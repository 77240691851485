@import '../../styles/main';

.parent-container {
  height: 100vh;
  background: $primary url("./who-we-are.png") no-repeat bottom;

  .container {
    background-color: transparent;
    div { background-color: transparent; }
    .card {
      background-color: $white;

      div {
        background-color: $white;
      }

      a {
        background-color: $primary;
        border-color: $primary;
      }
    }
  }
}
