@import '../../styles/main';

.content-container {
  background-color: $gray-900 !important;
  padding-top: 20px;
  img {
    border-radius: 50%;
    margin-bottom: 16px;
  }
  .client-name {
    padding-bottom: 3rem;
    a {
      font-size: 28px;
      color: $primary;
      @include font-face-Bree-W01-Thin-Oblique;
    }
  }
}
