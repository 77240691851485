@import '../../styles/main';

.parent-container1 {
  padding: 20px 0;
  background-color: $primary !important;

  div {
    background-color: $primary;
  }

  .main-title {
    font-size: 48px;
    font-weight: 700;
    @include font-face-Bree-W01-Thin-Oblique;
  }
  .sub-title {
    font-size: 36px;
    font-weight: 300;
    @include font-face-Bree-W01-Thin-Oblique;
    padding-bottom: 20px;
  }

  .email-address {
    a {
      color: $gray-900;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .social-media-icons {
    a {
      margin: 0 5px;
      color: $gray-900;
      .fa { font-size: 20px; }
    }
  }
}
