@import '../../styles/main';

.footer-line-sep {
  width: 100%;
  background-color: $primary;
  height: 3px;
  display: block;
}

.footer-content {
  color: $white;
  text-align: center;
  padding-top: 15px;

  a {
    padding: 15px 5px;
    text-decoration: none;
    color: $white;

    &:hover {
      color: $white;
    }

    .fa {font-size: large;}
  }

  .social-media-icons  {
    padding: 5px 0px;
  }

  .copyright {
    width: 100%;
    background-color: $primary;
    padding: 15px;
    color: $white;
    text-align: center;
  }

}

