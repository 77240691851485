@import "../../styles/main";

.banner-text-container {
  background-color: $primary;
  color: $gray-900;
  text-align: center;
  padding: 20px;

  .container {
    background-color: $primary;
  }

  .text-title {
    font-size: $sub-heading-font-size;
    font-weight: 700;
    padding: 15px 0 0px;
    @include font-face-Bree-W01-Thin-Oblique;
  }

  .text-sub-title {
    font-size: $sub-tagline-font-size;
    font-weight: 300;
    padding-bottom: 10px;
    @include font-face-Bree-W01-Thin-Oblique;
  }
}
