@import '../../styles/main';

.content-container {
  color: $white;
  text-align: center;
  padding: 45px 10px 15px;
  margin: 25px;

  .content-img {
    .grid-img {
      border-radius: 50%!important;
    }
  }
  .content-heading {
    color: $primary;
    padding: 20px 0 5px;
    font-size: x-large;
    a {
      color: $primary;
      &:hover {
        text-decoration: none;
        font-weight: bold;
      }
    }
  }
  .content-sub-heading {
    font-size: large;
    padding-bottom: 15px;
  }
  .content-description {
    //min-height: 150px;
  }
  .content-link {
    margin-top: 25px;
    border: solid 2px $primary;
    border-radius: 15px;
    color: $primary;
    padding: 10px;
    &:hover {
      background-color: $primary;
      color: $white;
    }
    a {
      color: $white;
      &:hover {
        font-weight: bold;
        color: $gray-400;
        text-decoration: none;
      }
    }
  }
}
