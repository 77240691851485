@import '../../node_modules/bootstrap/scss/bootstrap';
@import "fonts";

$primary: #f270b6;
$sub-heading-font-size: 48px;
$sub-tagline-font-size: 36px;


.page-container {
  background-color: $gray-900;
  font-family: Avenir Light,sans-serif;
}
.container {
  background-color: $gray-700;
}

