@import '../../styles/main';

.header-container {
  background-color: $gray-900 !important;
}
.row {
    background-color: $gray-900;
    padding: 20px 0;
  
    .logo-container {
      .container-item { float: left; }
    }
    .logo-img {
      height: 60px;
    }
    .brand-text {
      color: $primary;
      font-size: x-large;
      font-weight: bold;
      @include font-face-Bree-W01-Thin-Oblique;
    }
    .brand-tagline {
      color: $white;
      font-size: large;
    }
    .header-contacts {
      color: $white;
      text-align: right;
      a {
        color: $white;
        text-decoration: none;
        :hover {
          text-decoration: none;
        }
      }

    }

}
.navbar {
  background-color: $white;
  padding: 0px 1rem !important;
  a{
    color: $gray-900;
    &:hover { text-decoration: none; }
  }

  .navbar-toggler {
    margin: 5px;
    .navbar-toggler-icon {
      height: 1rem;
      width: 1rem;
    }
  }

  .nav-link {
    color: $black !important;
  }
  .container {
    background-color: $white;
  }
  .mr-auto a:hover {
    color: $primary !important;
  }

  .social-media-icons {
    a {
      margin: 0 5px;
      .fa { font-size: 20px; }
    }
  }

  .navbar-collapse {
    z-index: 11;
  }
}

.footer-line-sep {
  width: 100%;
  background-color: $primary;
  height: 3px;
  display: block;
}
