@import '../../styles/main';

.video-container {
  position: relative;
  height: 80vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}

.video-text {
  background-color: transparent;
  position: absolute;
  top: 30vh;
  width: 50%;
  text-align: center;
  z-index: 10;
  h1, h6 {
    font-weight: 700;
    @include font-face-Bree-W01-Thin-Oblique;
  }
}

